<script>
// @ts-nocheck

  import { CheckCircle2, ChevronRight} from "lucide-svelte";
  import { apiData } from "$lib/store";
  import { handlepricecard } from "$lib/crud";
  export let loggedin = false;





</script>




<div class="flex {loggedin ? "":"flex-col"} md:flex-row gap-6 md:justify-center items-center ">
    <div class="md:w-1/3 max-w-sm px-8 py-4 mt-16 bg-tan-400 rounded-3xl shadow-lg ">
        <div class="flex justify-center  -mt-9">
            <h2 class="flex w-2/3 h-12 border-2 border-black bg-tan-400 rounded-full justify-center items-center font-playfair  " >Starter</h2>
        </div>
    
        <h2 class="mt-2 text-xl font-semibold font-playfair text-gray-80">$19.99 / month</h2>
        
        
        <p class="mt-2 text-sm font-normal my-4 text-gray-600 dark:text-gray-200">Recomended if you place under 50 orders a month. </p>
        
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> 50 Orders a month</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Updates every day</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Parrallel Ordering</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Unlimited Users</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Includes Tracking</p>
    
    
        <div class="flex justify-center mt-4">
            {#if loggedin}
            <button on:click={() => handlepricecard('tbclas', $apiData.user.email)} class="text-lg border-2 border-black hover:bg-black hover:text-white transition-all duration-200  px-4 rounded-full font-normal text-emerald-900" tabindex="0" ><ChevronRight class="w-6 h-6" /></button>
            {:else if !loggedin}
            <a href="/login" class="text-lg border-2 border-black hover:bg-black hover:text-white transition-all duration-200  px-4 rounded-full font-normal text-emerald-900" tabindex="0" ><ChevronRight class="w-6 h-6" /></a>
            {/if}
        </div>
    </div>

    <div class="md:w-1/3 max-w-sm px-8 py-4 mt-16 bg-tan-400 rounded-3xl shadow-lg ">
        <div class="flex justify-center  -mt-9">
            <h2 class="flex w-2/3 h-12 border-2 border-black bg-tan-400 rounded-full justify-center items-center font-playfair  " >Advanced</h2>
        </div>
    
        <h2 class="mt-2 text-xl font-semibold font-playfair text-gray-80">$49.99 / month</h2>
        
        
        <p class="mt-2 text-sm font-normal my-4 text-gray-600 dark:text-gray-200">Recomended if you place over 50 orders a month. </p>
        
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" />150 Orders a month</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Updates 2x a day</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Parrallel Ordering</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Unlimited Users</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Includes Tracking</p>
    
    
        <div class="flex justify-center mt-4">
            {#if loggedin}
            <button on:click={() => handlepricecard('tbclaa', $apiData.user.email)} class="text-lg border-2 border-black hover:bg-black hover:text-white transition-all duration-200  px-4 rounded-full font-normal text-emerald-900" tabindex="0" ><ChevronRight class="w-6 h-6" /></button>
            {:else if !loggedin}
            <a href="/login" class="text-lg border-2 border-black hover:bg-black hover:text-white transition-all duration-200  px-4 rounded-full font-normal text-emerald-900" tabindex="0" ><ChevronRight class="w-6 h-6" /></a>
            {/if}
        </div>
    </div>
    <div class="md:w-1/3 max-w-sm px-8 py-4 mt-16 bg-tan-400 rounded-3xl shadow-lg ">
        <div class="flex justify-center  -mt-9">
            <h2 class="flex w-2/3 h-12 border-2 border-black bg-tan-400 rounded-full justify-center items-center font-playfair  " >Enterprise</h2>
        </div>
    
        <h2 class="mt-2 text-xl font-semibold font-playfair text-gray-80">$99.99 / month</h2>
        
        
        <p class="mt-2 text-sm font-normal my-4 text-gray-600 dark:text-gray-200">Recomended if you place over 150 orders a month. </p>
        
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" />300 Orders a month</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Updates 4x a day</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Parrallel Ordering</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Unlimited Users</p>
        <p class="text-gray-800"><CheckCircle2 class="inline-flex my-1 text-emerald-700" /> Includes Tracking</p>
    
    
        <div class="flex justify-center mt-4">
            {#if loggedin}
            <button on:click={() => handlepricecard('tbclae', $apiData.user.email)} class="text-lg border-2 border-black hover:bg-black hover:text-white transition-all duration-200  px-4 rounded-full font-normal text-emerald-900" tabindex="0" ><ChevronRight class="w-6 h-6" /></button>
            {:else if !loggedin}
            <a href="/login" class="text-lg border-2 border-black hover:bg-black hover:text-white transition-all duration-200  px-4 rounded-full font-normal text-emerald-900" tabindex="0" ><ChevronRight class="w-6 h-6" /></a>
            {/if}
        </div>
    </div>

</div>